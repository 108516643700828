import React, { Fragment } from 'react'
import { useTemplatesLoader } from "@newageerp/v3.templates.templates-core";

interface Props {
    fieldKey: string,
}

export default function FilesIcon(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const { element } = tData;
    
    if (!element) {
        return <Fragment />;
    }

    const hasFiles = !!element.file && element.file.length > 0;

    if (!hasFiles) {
        return <Fragment />
    }

    return (
        <i className="fa-solid fa-paperclip"></i>
    )
}
