import { IBankAccountFieldsNae } from './useBankAccountHookNae';
import { IClientFieldsNae } from './useClientHookNae';
import { ICompanyFieldsNae } from './useCompanyHookNae';
import { IInvoiceFieldsNae } from './useInvoiceHookNae';
import { INoteFieldsNae } from './useNoteHookNae';
import { IPaymentFieldsNae } from './usePaymentHookNae';
import { IPaymentCategoryFieldsNae } from './usePaymentCategoryHookNae';
import { IPaymentClientFieldsNae } from './usePaymentClientHookNae';
import { IPaymentInfoChangeFieldsNae } from './usePaymentInfoChangeHookNae';
import { IPaymentProjectFieldsNae } from './usePaymentProjectHookNae';
import { IUserFieldsNae } from './useUserHookNae';
import { SelectorBankAccountNae,SelectorClientNae,SelectorCompanyNae,SelectorInvoiceNae,SelectorNoteNae,SelectorPaymentNae,SelectorPaymentCategoryNae,SelectorPaymentClientNae,SelectorPaymentInfoChangeNae,SelectorPaymentProjectNae,SelectorUserNae, } from "../models/ormSelectors"
import { NaeSSchemaMap } from '../config/NaeSSchema';
import { store } from '../models/ormstore';

export const getDataCacheForSchema = (className: string) => {
    if (className === NaeSSchemaMap.BankAccount.className) {
            return {
                dataToCheck: SelectorBankAccountNae(store.getState()),
                fields: IBankAccountFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.Client.className) {
            return {
                dataToCheck: SelectorClientNae(store.getState()),
                fields: IClientFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.Company.className) {
            return {
                dataToCheck: SelectorCompanyNae(store.getState()),
                fields: ICompanyFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.Invoice.className) {
            return {
                dataToCheck: SelectorInvoiceNae(store.getState()),
                fields: IInvoiceFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.Note.className) {
            return {
                dataToCheck: SelectorNoteNae(store.getState()),
                fields: INoteFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.Payment.className) {
            return {
                dataToCheck: SelectorPaymentNae(store.getState()),
                fields: IPaymentFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.PaymentCategory.className) {
            return {
                dataToCheck: SelectorPaymentCategoryNae(store.getState()),
                fields: IPaymentCategoryFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.PaymentClient.className) {
            return {
                dataToCheck: SelectorPaymentClientNae(store.getState()),
                fields: IPaymentClientFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.PaymentInfoChange.className) {
            return {
                dataToCheck: SelectorPaymentInfoChangeNae(store.getState()),
                fields: IPaymentInfoChangeFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.PaymentProject.className) {
            return {
                dataToCheck: SelectorPaymentProjectNae(store.getState()),
                fields: IPaymentProjectFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.User.className) {
            return {
                dataToCheck: SelectorUserNae(store.getState()),
                fields: IUserFieldsNae,    
            }
        }
        return undefined;
}