import { createSelector } from "redux-orm";
import orm from "./orm";

export const SelectorBankAccountNae = createSelector(orm.BankAccountModel);
export const SelectorClientNae = createSelector(orm.ClientModel);
export const SelectorCompanyNae = createSelector(orm.CompanyModel);
export const SelectorInvoiceNae = createSelector(orm.InvoiceModel);
export const SelectorNoteNae = createSelector(orm.NoteModel);
export const SelectorPaymentNae = createSelector(orm.PaymentModel);
export const SelectorPaymentCategoryNae = createSelector(orm.PaymentCategoryModel);
export const SelectorPaymentClientNae = createSelector(orm.PaymentClientModel);
export const SelectorPaymentInfoChangeNae = createSelector(orm.PaymentInfoChangeModel);
export const SelectorPaymentProjectNae = createSelector(orm.PaymentProjectModel);
export const SelectorQueueNae = createSelector(orm.QueueModel);
export const SelectorUserNae = createSelector(orm.UserModel);

export const selectorBySchemaClassName = (schema) => {
        if (schema === 'BankAccount') {
        return SelectorBankAccountNae;
    }
        if (schema === 'Client') {
        return SelectorClientNae;
    }
        if (schema === 'Company') {
        return SelectorCompanyNae;
    }
        if (schema === 'Invoice') {
        return SelectorInvoiceNae;
    }
        if (schema === 'Note') {
        return SelectorNoteNae;
    }
        if (schema === 'Payment') {
        return SelectorPaymentNae;
    }
        if (schema === 'PaymentCategory') {
        return SelectorPaymentCategoryNae;
    }
        if (schema === 'PaymentClient') {
        return SelectorPaymentClientNae;
    }
        if (schema === 'PaymentInfoChange') {
        return SelectorPaymentInfoChangeNae;
    }
        if (schema === 'PaymentProject') {
        return SelectorPaymentProjectNae;
    }
        if (schema === 'Queue') {
        return SelectorQueueNae;
    }
        if (schema === 'User') {
        return SelectorUserNae;
    }
    }

export const selectorBySchemaSlug = (schema) => {
        if (schema === 'bank-account') {
        return SelectorBankAccountNae;
    }
        if (schema === 'client') {
        return SelectorClientNae;
    }
        if (schema === 'company') {
        return SelectorCompanyNae;
    }
        if (schema === 'invoice') {
        return SelectorInvoiceNae;
    }
        if (schema === 'note') {
        return SelectorNoteNae;
    }
        if (schema === 'payment') {
        return SelectorPaymentNae;
    }
        if (schema === 'payment-category') {
        return SelectorPaymentCategoryNae;
    }
        if (schema === 'payment-client') {
        return SelectorPaymentClientNae;
    }
        if (schema === 'payment-info-change') {
        return SelectorPaymentInfoChangeNae;
    }
        if (schema === 'payment-project') {
        return SelectorPaymentProjectNae;
    }
        if (schema === '') {
        return SelectorQueueNae;
    }
        if (schema === 'user') {
        return SelectorUserNae;
    }
    }