import React, { Fragment, useEffect } from 'react'

import { useTranslation } from 'react-i18next';
import { Table, Td, Th } from '@newageerp/v3.bundles.layout-bundle'
import { Checkbox } from '@newageerp/ui.form.base.form-pack';
import { NaeSSchemaMap } from '../../_generated/_custom/config/NaeSSchema';
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import { usePaymentClientHookNae } from '../../_generated/_custom/hooks/usePaymentClientHookNae';
import { useUList, useURequest } from '@newageerp/v3.bundles.hooks-bundle';
import { NaePathsMap } from '../../_generated/_custom/config/NaePaths';
import { PropsId } from '../../_generated/_custom/models-cache-data/types';

export default function UserPaymentClientWidget(props: PropsId) {
    const { t } = useTranslation();

    const [getUser, usersData] = useUList(NaeSSchemaMap.User.schema, ['id', 'paymentClients:id']);
    const [getClients, clientsData] = useUList(NaeSSchemaMap.PaymentClient.schema, ['id']);

    const [addToUser, addToUserParams] = useURequest(NaePathsMap.post['PaymentClientsUserControllerAdd']);
    const [removeFromUser, removeFromUserParams] = useURequest(NaePathsMap.post['PaymentClientsUserControllerRemove']);

    const addClient = (clientId: number) => {
        addToUser({
            userId: props.id,
            clientId: clientId,
        }).then(() => {
            getUserData();
        })
    }

    const removeClient = (clientId: number) => {
        removeFromUser({
            userId: props.id,
            clientId: clientId,
        }).then(() => {
            getUserData();
        })
    }

    const getData = () => {
        getClients(
            [],
            1,
            1000,
            [{ key: 'i.title', value: 'ASC' }]
        );
        getUserData();
    }
    const getUserData = () => {
        getUser(
            [
                {
                    "and": [
                        ["i.id", "=", props.id, true]
                    ]
                }
            ],
            1,
            1,
            [{ key: 'i.id', value: 'ASC' }]
        )
    }

    const element: any = usersData.data.data.length > 0 ? usersData.data.data[0] : undefined;

    useEffect(() => {
        getData();
    }, []);

    if (!element) {
        return <Fragment />
    }

    const isLoading = addToUserParams.loading || removeFromUserParams.loading;

    return (
        <Table
            className={isLoading ? 'tw3-animate-pulse' : ''}
            thead={
                <thead>
                    <tr>
                        <Th className='tw3-w-20'></Th>
                        <Th>{t('Client')}</Th>
                    </tr>
                </thead>
            }
            tbody={
                <tbody>
                    {clientsData.data.data.map((i: any) => {
                        return (
                            <ClientLine key={`client-${i.id}`} id={i.id} element={element} addClient={addClient} removeClient={removeClient} />
                        )
                    })}
                </tbody>
            }
        />
    )
}

interface ClientLineProps {
    id: number,
    element: any,

    addClient: (val: number) => void,
    removeClient: (val: number) => void,
}
const ClientLine = (props: ClientLineProps) => {
    const client = usePaymentClientHookNae(props.id);

    if (!client) {
        return <Fragment />
    }

    const userClients: number[] = props.element.paymentClients ? props.element.paymentClients.map((i: any) => i.id) : [];
    const isChecked = userClients.indexOf(client.id) >= 0;

    const toggle = () => {
        if (isChecked) {
            props.removeClient(props.id);
        } else {
            props.addClient(props.id);
        }
    }

    return <tr>
        <Td>
            <Checkbox value={isChecked} onChange={toggle} />
        </Td>
        <Td>
            {client.title}
        </Td>
    </tr>
}
