import * as NotesBundle from '@newageerp/v3.bundles.notes-bundle'
import * as BookmarksBundle from '@newageerp/v3.bundles.bookmarks-bundle'
import { Fragment } from 'react';
import PaymentsZip from './PaymentsZip/PaymentsZip';
import UserWidget from '../UserComponents/Widgets/UserWidget';
import UserPaymentClientWidget from '../UserComponents/Widgets/UserPaymentClientWidget';
import UserCompanyWidget from '../UserComponents/Widgets/UserCompanyWidget';
import BankAccountWidget from '../UserComponents/Widgets/BankAccountWidget';
import PaymentProjectWidget from '../UserComponents/Widgets/PaymentProjectWidget';
import PaymentClientWidget from '../UserComponents/Widgets/PaymentClientWidget';
import PaymentCategoryWidget from '../UserComponents/Widgets/PaymentCategoryWidget';
import PaymentWidget from '../UserComponents/Widgets/PaymentWidget';
import UserClientWidget from '../UserComponents/Widgets/UserClientWidget';

export const PluginsMap: any = {
    "CustomNotesApp": (comp: string) => {
        if (comp === 'NotesNameResolver') {
            return Fragment;
        }
        // @ts-ignore
        return NotesBundle[comp];
    },
    "CustomBookmarksApp": (comp: string) => {
        // @ts-ignore
        return BookmarksBundle[comp];
    },


    'custom.PaymentsZip.PaymentsZip': PaymentsZip,

    'custom.users.UserWidget': UserWidget,
    'custom.users.UserCompanyWidget': UserCompanyWidget,
    'custom.users.UserPaymentClientWidget': UserPaymentClientWidget,
    'custom.users.UserClientWidget': UserClientWidget,

    'custom.balance.BankAccountWidget': BankAccountWidget,
    'custom.balance.PaymentProjectWidget': PaymentProjectWidget,
    'custom.balance.PaymentClientWidget': PaymentClientWidget,
    'custom.balance.PaymentCategoryWidget': PaymentCategoryWidget,
    'custom.balance.PaymentWidget': PaymentWidget,
}