import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
// import "react-big-calendar/lib/css/react-big-calendar.css";

// import { PaymentStatusesList } from "../../_generated/statuses/badges/PaymentStatuses";
import { groupMap } from "@newageerp/v3.bundles.utils-bundle";
import { NaeSSchemaMap } from "../../_generated/_custom/config/NaeSSchema";
import { Popup } from "@newageerp/v3.bundles.popup-bundle";
import { TemplatesLoader } from "@newageerp/v3.templates.templates-core";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";
import { NaeSStatuses } from "../../_generated/_custom/config/NaeSStatuses";

const localizer = momentLocalizer(moment);

interface CalendarDate {
  start: string;
  end: string;
}

interface IPayment {
  id: number;
  balanceEur: number;
  type: string;
  totalEur: number;
  date: string;
  status: number;
}

export default function CalendarComponent() {
  const [paymentsPopup, setPaymentsPopup] = useState<any[]>([]);

  const [getPayments, paymentsData] = useUList(
    NaeSSchemaMap.Payment.schema,
    ["id", "balanceEur", "type", "totalEur", "date", "status"]
  );

  const [events, setEvents] = useState<any[]>([]);

  const [range, setRange] = useState<CalendarDate>({
    start: moment().startOf("month").format("YYYY-MM-DD"),
    end: moment().endOf("month").format("YYYY-MM-DD"),
  });

  useEffect(() => {
    getPayments(
      [
        {
          and: [
            ["i.date", "dgte", range.start, true],
            ["i.date", "dlte", range.end, true],
          ],
        },
      ],
      1,
      10000,
      [{ key: "i.id", value: "DESC" }]
    );
  }, [range]);

  useEffect(() => {
    const groupedByDate = groupMap(
      paymentsData.data.data,
      (el) => moment(el.date).format("YYYY-MM-DD")
    );
    const _events: any[] = [];

    Object.keys(groupedByDate).map((d) => {
      const _data = groupedByDate[d];
      NaeSStatuses.filter((s) => s.schema === NaeSSchemaMap.Payment.schema && s.type === 'status').forEach((s) => {
        const _statusData = _data.filter((item: IPayment) => item.status === s.status);
        const ids = _statusData.map((item: IPayment) => item.id);


        let bgColor = `tw3-bg-${s.variant}-500`;
        let textColor = undefined;
        if (s.variant === 'slateorange') {
          bgColor = `tw3-bg-slate-200`;
          textColor = 'tw3-text-orange-500';
        }


        if (_statusData.length > 0) {
          _events.push({
            start: moment(d).toDate(),
            end: moment(d).toDate(),
            title: `${s.text} - ${_statusData.length}`,
            bgColor: bgColor,
            textColor: textColor,
            ids: ids,
          });
        }
      })
      // const allPayments: number[] = _data.map((i: IPayment) => i.balanceEur);
      // const outPayments: number[] = _data
      //   .filter((i: IPayment) => i.type === "out")
      //   .map((i: IPayment) => i.totalEur);
      // const inPayments: number[] = _data
      //   .filter((i: IPayment) => i.type === "in")
      //   .map((i: IPayment) => i.totalEur);

      // const outPaymentsSum = outPayments.reduce(
      //   (partialSum, a) => partialSum + a,
      //   0
      // );
      // const inPaymentsSum = inPayments.reduce(
      //   (partialSum, a) => partialSum + a,
      //   0
      // );
      // const allPaymentsSum = allPayments.reduce(
      //   (partialSum, a) => partialSum + a,
      //   0
      // );

      // _events.push({
      //   start: moment(d).toDate(),
      //   end: moment(d).toDate(),
      //   title: `In ${inPaymentsSum.toFixed(2)}`,
      // });
      // _events.push({
      //   start: moment(d).toDate(),
      //   end: moment(d).toDate(),
      //   title: `Out ${outPaymentsSum.toFixed(2)}`,
      // });
      // _events.push({
      //   start: moment(d).toDate(),
      //   end: moment(d).toDate(),
      //   title: `Total ${allPaymentsSum.toFixed(2)}`,
      // });
    });
    setEvents(_events);
  }, [paymentsData.data.data]);

  return (
    <div>
      <Calendar
        defaultView={"month"}
        views={["month"]}
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 900 }}
        onRangeChange={(e: any) =>
          setRange({
            start: moment(e.start).format("YYYY-MM-DD"),
            end: moment(e.end).format("YYYY-MM-DD"),
          })
        }
        eventPropGetter={(event, start, end, isSelected) => {
          return {
            style: {
              // background: 'none',
              color: event.textColor ? undefined : '#FFF',
              textAlign: "right",
            },
            className: `font-medium text ${event.bgColor} ${event.textColor ? event.textColor : ''}`,
          };
        }}
        onDoubleClickEvent={(e: any) => {
          setPaymentsPopup(e.ids);
        }}
        popup={true}
      />
      {!!paymentsPopup && paymentsPopup.length > 0 &&
        <Popup isPopup={true} onClick={() => setPaymentsPopup([])} title={""} >
          <TemplatesLoader
            templateName="PageInlineList"
            data={{
              schema: "payment",
              type: "main",
              extraFilters: [{
                "and": [
                  ['i.id', 'in', paymentsPopup, true]
                ]
              }]
            }}
          />
        </Popup>}
    </div>
  );
}
