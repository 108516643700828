import { OpenApi } from "@newageerp/nae-react-auth-wrapper";
import React, { Fragment, useEffect, useState } from "react";

import { getSchemaTitle } from "../../_generated/v3/utils";

import { NaeSSchemaMap } from "../../_generated/_custom/config/NaeSSchema";
import { useBankAccountHookNae } from "../../_generated/_custom/hooks/useBankAccountHookNae";
import { MainToolbarTitle } from "@newageerp/v3.bundles.layout-bundle";
import { NumberCardWidget, NumberCardWidgetColors } from "@newageerp/v3.bundles.widgets-bundle";
import { PropsId } from "../../_generated/_custom/models-cache-data/types";
import { usePaymentCategoryHookNae } from "../../_generated/_custom/hooks/usePaymentCategoryHookNae";
import { usePaymentProjectHookNae } from "../../_generated/_custom/hooks/usePaymentProjectHookNae";
import { usePaymentClientHookNae } from "../../_generated/_custom/hooks/usePaymentClientHookNae";
import { axiosInstance } from "@newageerp/v3.bundles.utils-bundle";
import { useTemplatesCore } from "@newageerp/v3.templates.templates-core";
import { SFSOpenViewModalWindowProps } from "@newageerp/v3.bundles.popup-bundle";
import { useUList } from "@newageerp/v3.bundles.hooks-bundle";

interface IFollow {
  id: number;
  sourceId: number;
  sourceSchema: string;
}

const defElement: IFollow = {
  id: 0,
  sourceId: 0,
  sourceSchema: "",
};

export default function Dashboard() {
  const {userState} = useTemplatesCore()
  const [remoteData, setRemoteData] = useState([]);

  const loadData = () => {
    const url = '/app/proxy/bookmarks/get-for-user';
    axiosInstance
      .post(
        url,
        { data: { user: userState.id } }
      )
      .then((response) => {
        if (response.status === 200) {
          let _listSchema: any = {}

          setRemoteData(response.data.data)
        } else {
          // UIConfig.toast.error(t('Klaida'))
        }
      });
  };
  useEffect(() => {
    loadData();
  }, []);

  return (
    <Fragment>
      <MainToolbarTitle title="Dashboard" />
      <div className="space-y-4">
        <ModuleListWidget
          schema={NaeSSchemaMap.BankAccount.schema}
          hook={useBankAccountHookNae}
          variant={"blue"}
          ids={remoteData
            .filter(
              (i: IFollow) => i.sourceSchema === NaeSSchemaMap.BankAccount.schema
            )
            .map((i: IFollow) => i.sourceId)}
        />
        <ModuleListWidget
          schema={NaeSSchemaMap.PaymentClient.schema}
          hook={usePaymentClientHookNae}
          variant={"slate"}
          ids={remoteData
            .filter(
              (i: IFollow) =>
                i.sourceSchema === NaeSSchemaMap.PaymentClient.schema
            )
            .map((i: IFollow) => i.sourceId)}
        />
        <ModuleListWidget
          schema={NaeSSchemaMap.PaymentProject.schema}
          hook={usePaymentProjectHookNae}
          variant={"purple"}
          ids={remoteData
            .filter(
              (i: IFollow) =>
                i.sourceSchema === NaeSSchemaMap.PaymentProject.schema
            )
            .map((i: IFollow) => i.sourceId)}
        />
        <ModuleListWidget
          schema={NaeSSchemaMap.PaymentCategory.schema}
          hook={usePaymentCategoryHookNae}
          variant={"amber"}
          ids={remoteData
            .filter(
              (i: IFollow) =>
                i.sourceSchema === NaeSSchemaMap.PaymentCategory.schema
            )
            .map((i: IFollow) => i.sourceId)}
        />
      </div>
    </Fragment>
  );
}

interface ModuleListWidgetProps {
  schema: string;
  hook: any;
  variant: keyof typeof NumberCardWidgetColors;
  ids: number[];
}

const ModuleListWidget = (props: ModuleListWidgetProps) => {
  const [getData, moduleData] = useUList<PropsId>(props.schema, ["id"]);
  useEffect(() => {
    if (props.ids.length > 0) {
      getData([{ and: [["i.id", "in", props.ids, true]] }], 1, 1000, [
        { key: "i.title", value: "ASC" },
      ]);
    }
  }, [props.ids]);

  if (moduleData.data.data.length === 0) {
    return <Fragment />;
  }

  return (
    <div className="space-y-2">
      <div className={`tw3-text-sky-900 tw3-font-medium`}>
        {getSchemaTitle(props.schema, true)}
      </div>
      <div className="grid grid-cols-3 gap-8">
        {moduleData.data.data.map((bAcc) => {
          return (
            <ModuleElementWidget
              id={bAcc.id}
              hook={props.hook}
              variant={props.variant}
              schema={props.schema}
            />
          );
        })}
      </div>
    </div>
  );
};

interface ModuleElementWidgetProps {
  id: number;
  hook: any;
  variant: keyof typeof NumberCardWidgetColors;
  schema: string;
}

const ModuleElementWidget = (props: ModuleElementWidgetProps) => {
  const element = props.hook(props.id);

  if (!element) {
    return <Fragment />;
  }

  return (
    <div
      className="tw3-cursor-pointer"
      onClick={() => {
        SFSOpenViewModalWindowProps({
          id: props.id,
          schema: props.schema,
          type: 'main'
        });
      }}
    >
      <NumberCardWidget
        title={element.title}
        children={element.balanceEur}
        color={props.variant}
        asFloat={true}
        currency={"EUR"}
      />
    </div>
  );
};
