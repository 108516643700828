import { ORM } from "redux-orm";
import BankAccountModel from "./BankAccountModel"
import ClientModel from "./ClientModel"
import CompanyModel from "./CompanyModel"
import InvoiceModel from "./InvoiceModel"
import NoteModel from "./NoteModel"
import PaymentModel from "./PaymentModel"
import PaymentCategoryModel from "./PaymentCategoryModel"
import PaymentClientModel from "./PaymentClientModel"
import PaymentInfoChangeModel from "./PaymentInfoChangeModel"
import PaymentProjectModel from "./PaymentProjectModel"
import QueueModel from "./QueueModel"
import UserModel from "./UserModel"

export const orm = new ORM({
    stateSelector: (state) => {
        return state.orm; // wherever the reducer is put during createStore
    },
});
orm.register(
    BankAccountModel,
    ClientModel,
    CompanyModel,
    InvoiceModel,
    NoteModel,
    PaymentModel,
    PaymentCategoryModel,
    PaymentClientModel,
    PaymentInfoChangeModel,
    PaymentProjectModel,
    QueueModel,
    UserModel,
);

export default orm;