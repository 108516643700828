import React, { Fragment } from 'react'
import { NumberCardWidget } from '@newageerp/v3.bundles.widgets-bundle'
import { usePaymentHookNae } from '../../_generated/_custom/hooks/usePaymentHookNae';
import { PropsId } from '../../_generated/_custom/models-cache-data/types';
export default function PaymentWidget(props: PropsId) {
    const element = usePaymentHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    return (
        <Fragment>
            <NumberCardWidget color='sky' isCompact={true} asFloat={true} currency='EUR' title='Balance' children={element.balanceEur}/>
            <NumberCardWidget color='purple' isCompact={true} asFloat={true} currency='EUR' title='Client commission' children={element.calculatedCommission}/>
            <NumberCardWidget color='orange' isCompact={true} asFloat={true} currency='EUR' title='Bank commission' children={element.calculatedBankCommission}/>
        </Fragment>
    )
}
