import React, { Fragment } from 'react'
import { useTemplatesCore, useTemplatesLoader } from "@newageerp/v3.templates.templates-core";
import { NaeSSchemaMap } from '../../../config/NaeSSchema';
import { StatusWidget } from '@newageerp/v3.bundles.widgets-bundle';

interface Props {
    fieldKey: string,
}

export default function SubStatus(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const { element } = tData;
    const { modules } = useTemplatesCore();

    if (!element) {
        return <Fragment />;
    }

    const elementStatus = element[props.fieldKey];
    const statusColor = modules.statuses.color(NaeSSchemaMap.Payment.schema, props.fieldKey, elementStatus);
    const statusText = modules.statuses.title(NaeSSchemaMap.Payment.schema, props.fieldKey, elementStatus);

    return <StatusWidget
        title={statusText}
        className="tw3-max-w-[120px] tw3-truncate"
        color={statusColor}
    >
        {statusText.length > 2 ? statusText.substring(0, 2) : statusText}
    </StatusWidget>;
}
