import React from 'react'
import { useIdleTimer } from 'react-idle-timer'
import App from './_generated/v3/app/App';

export default function AppWrapper() {
    const onIdle = () => {
        window.localStorage.setItem("token", "");
        window.location.href = "/";
    }

    const {
        start,
        reset,
        activate,
        pause,
        resume,
        isIdle,
        isPrompted,
        isLeader,
        getTabId,
        getRemainingTime,
        getElapsedTime,
        getLastIdleTime,
        getLastActiveTime,
        getTotalIdleTime,
        getTotalActiveTime
    } = useIdleTimer({
        onIdle,
        timeout: 1000 * 60 * 180,

    })


    return (
        <App />
    )
}
