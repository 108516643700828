import { useEffect } from "react";
import { NaeSSchemaMap } from '../../_custom/config/NaeSSchema';
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorPaymentCategoryNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface IPaymentCategoryModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      title: string,
      balanceEur: number,
      commissionBalanceEur: number,
      balanceCurrency: any,
      commissionCurrency: any,
  }

export const IPaymentCategoryFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "title",
    "balanceEur",
    "commissionBalanceEur",
    "balanceCurrency",
    "commissionCurrency"
];

export function usePaymentCategoryHookNae(id: number) : IPaymentCategoryModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorPaymentCategoryNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: NaeSSchemaMap.PaymentCategory.schema,
        fields: IPaymentCategoryFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
