import { NumberCardWidget } from '@newageerp/v3.bundles.widgets-bundle';
import React, { Fragment } from 'react'
import { usePaymentClientHookNae } from '../../_generated/_custom/hooks/usePaymentClientHookNae';
import { PropsId } from '../../_generated/_custom/models-cache-data/types';

export default function PaymentClientWidget(props: PropsId) {
    const element: any = usePaymentClientHookNae(props.id);
    if (!element) {
        return <Fragment />
    }

    const balanceCurrencies = Object.keys(element.balanceCurrency);
    const commissionCurrencies = Object.keys(element.commissionCurrency);

    return (
        <Fragment>
            {balanceCurrencies.map(b => {
                const bVal = element.balanceCurrency[b];

                if (bVal === 0.0) {
                    return <Fragment key={`b-${b}`} />
                }

                return <NumberCardWidget key={`b-${b}`} color='sky' isCompact={true} asFloat={true} currency={b} title='Balance' children={bVal} />
            })}
            {commissionCurrencies.map(b => {
                const bVal = element.commissionCurrency[b];

                if (bVal === 0.0) {
                    return <Fragment key={`c-${b}`} />
                }

                return <NumberCardWidget key={`c-${b}`} color='purple' isCompact={true} asFloat={true} currency={b} title='Commission' children={bVal} />
            })}

            {balanceCurrencies.map(b => {
                const bVal = element.balanceCurrency[b];
                const b2Val = element.commissionCurrency[b];

                if (bVal === 0.0) {
                    return <Fragment key={`b-${b}`} />
                }

                return <NumberCardWidget key={`d-${b}`} color='orange' isCompact={true} asFloat={true} currency={b} title='Total' children={bVal + b2Val} />
            })}

        </Fragment>
    )
}
