import React, { Fragment } from 'react'
import { NumberCardWidget } from '@newageerp/v3.bundles.widgets-bundle'
import { useBankAccountHookNae } from '../../_generated/_custom/hooks/useBankAccountHookNae';
import { PropsId } from '../../_generated/_custom/models-cache-data/types';

export default function BankAccountWidget(props: PropsId) {
    const element = useBankAccountHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    return (
        <Fragment>
            <NumberCardWidget color='sky' isCompact={true} asFloat={true} currency={element.currency.currency} title='Balance' children={element.balanceEur}/>

            <NumberCardWidget color='purple' isCompact={true} asFloat={true} currency={element.currency.currency} title='Commission' children={element.commissionBalanceEur}/>

            <NumberCardWidget color='orange' isCompact={true} asFloat={true} currency={element.currency.currency} title='Total' children={element.balanceEur + element.commissionBalanceEur}/>
        </Fragment>
    )
}
