import SubStatus4cc0f from "./components/Payment/SubStatus";
import FloatColorize1528d from "./components/Payment/FloatColorize";
import FilesIcon25717 from "./components/Payment/FilesIcon";
import BalanceCurrency63efd from "./components/Common/BalanceCurrency";
import Changesfb0bf from "./components/payment/Changes";
export const CustomListComponentsMap: any = {
    'customlist.Payment/SubStatus': SubStatus4cc0f,
    'customlist.Payment/FloatColorize': FloatColorize1528d,
    'customlist.Payment/FilesIcon': FilesIcon25717,
    'customlist.Common/BalanceCurrency': BalanceCurrency63efd,
    'customlist.payment/Changes': Changesfb0bf,
}