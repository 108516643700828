import React, { Fragment } from 'react'
import { Route } from 'react-router-dom'
import CalendarComponent from '../../../UserComponents/Dashboard/CalendarComponent'
import Dashboard from '../../../UserComponents/Dashboard/Dashboard'

export default function CustomUserWrapperRoutes() {
  return (
    <Fragment>
      <Route path={"/c/dashboard"}>
        <Dashboard />
      </Route>
      <Route path={"/c/calendar"}>
        <CalendarComponent />
      </Route>
    </Fragment>
  )
}
