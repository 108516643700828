
import React, { Fragment } from 'react'
import { PropsId, PropsLink } from './types';
import moment from "moment";
import { SelectorBankAccountNae, SelectorClientNae, SelectorCompanyNae, SelectorInvoiceNae, SelectorNoteNae, SelectorPaymentNae, SelectorPaymentCategoryNae, SelectorPaymentClientNae, SelectorPaymentInfoChangeNae, SelectorPaymentProjectNae, SelectorUserNae } from '../models/ormSelectors';
import { useBankAccountHookNae } from '../hooks/useBankAccountHookNae';
import { useClientHookNae } from '../hooks/useClientHookNae';
import { useCompanyHookNae } from '../hooks/useCompanyHookNae';
import { useInvoiceHookNae } from '../hooks/useInvoiceHookNae';
import { useNoteHookNae } from '../hooks/useNoteHookNae';
import { usePaymentHookNae } from '../hooks/usePaymentHookNae';
import { usePaymentCategoryHookNae } from '../hooks/usePaymentCategoryHookNae';
import { usePaymentClientHookNae } from '../hooks/usePaymentClientHookNae';
import { usePaymentInfoChangeHookNae } from '../hooks/usePaymentInfoChangeHookNae';
import { usePaymentProjectHookNae } from '../hooks/usePaymentProjectHookNae';
import { useUserHookNae } from '../hooks/useUserHookNae';


export const useEmptyHook = (id: number) => {
            return undefined;
        }

export const getHookForSchema = (schema: string) => {
  let selector : any = useEmptyHook;
  if (schema === 'bank-account') {
    return useBankAccountHookNae;
  }
  if (schema === 'client') {
    return useClientHookNae;
  }
  if (schema === 'company') {
    return useCompanyHookNae;
  }
  if (schema === 'invoice') {
    return useInvoiceHookNae;
  }
  if (schema === 'note') {
    return useNoteHookNae;
  }
  if (schema === 'payment') {
    return usePaymentHookNae;
  }
  if (schema === 'payment-category') {
    return usePaymentCategoryHookNae;
  }
  if (schema === 'payment-client') {
    return usePaymentClientHookNae;
  }
  if (schema === 'payment-info-change') {
    return usePaymentInfoChangeHookNae;
  }
  if (schema === 'payment-project') {
    return usePaymentProjectHookNae;
  }
  if (schema === 'user') {
    return useUserHookNae;
  }
  return selector;
}

export function BankAccountEmptyFieldNae(props: PropsLink) {
  const element = useBankAccountHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function ClientEmptyFieldNae(props: PropsLink) {
  const element = useClientHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function CompanyEmptyFieldNae(props: PropsLink) {
  const element = useCompanyHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function InvoiceEmptyFieldNae(props: PropsLink) {
  const element = useInvoiceHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function NoteEmptyFieldNae(props: PropsLink) {
  const element = useNoteHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function PaymentEmptyFieldNae(props: PropsLink) {
  const element = usePaymentHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function PaymentCategoryEmptyFieldNae(props: PropsLink) {
  const element = usePaymentCategoryHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function PaymentClientEmptyFieldNae(props: PropsLink) {
  const element = usePaymentClientHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function PaymentInfoChangeEmptyFieldNae(props: PropsLink) {
  const element = usePaymentInfoChangeHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function PaymentProjectEmptyFieldNae(props: PropsLink) {
  const element = usePaymentProjectHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function UserEmptyFieldNae(props: PropsLink) {
  const element = useUserHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

