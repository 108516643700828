import { useTemplatesLoader } from '@newageerp/v3.templates.templates-core';
import classNames from 'classnames';
import React, { Fragment } from 'react'

import { getPropertyForPath } from '../../../../v3/utils';

interface Props {
    fieldKey: string,
}

export default function TypeButtons(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const { element, updateElement } = tData;

    const prop = getPropertyForPath(`payment.${props.fieldKey}`)

    if (!element || !prop) {
        return <Fragment />;
    }

    const value = element[props.fieldKey];
    const updateValue = (e: any) => updateElement(props.fieldKey, e);

    return (
        <div className='tw3-flex tw3-gap-2'>
            {prop.enum?.map((en) => {
                const isActive = value === en.value;
                return (
                    <span
                        key={`en-${en.value}`}
                        className={
                            classNames(
                                'tw3-px-4 tw3-py-2',
                                'hover:tw3-text-sky-600',
                                "tw3-cursor-pointer",
                                { 'tw3-border-b tw3-border-sky-600': isActive }
                            )
                        }
                        onClick={() => updateValue(en.value)}
                    >
                        {en.label}
                    </span>
                )
            })}
        </div>
    )
}
