import React, { Fragment } from 'react'
import { useTemplatesLoader } from "@newageerp/v3.templates.templates-core";
import { numberFormat } from '@newageerp/v3.bundles.utils-bundle';

interface Props {
    fieldKey: string,
}

export default function BalanceCurrency(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const { element } = tData;

    if (!element) {
        return <Fragment />;
    }

    const value = element[props.fieldKey];

    const balanceCurrencies = Object.keys(value);

    return (
        <div>
            {balanceCurrencies.map(b => {
                const bVal = element.balanceCurrency[b];
                
                if (bVal === 0.0) {
                    return <Fragment key={`b-${b}`}/>
                }

                return <p className='tw3-text-right'>{numberFormat({userInput: bVal, decimals: 2, thousandsSep: ' '})} {b}</p>
            })}
        </div>
    )
}
