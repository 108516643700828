import { useEffect } from "react";
import { NaeSSchemaMap } from '../../_custom/config/NaeSSchema';
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorPaymentClientNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface IPaymentClientModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      title: string,
      balanceEur: number,
      projects: ChildId[],
      commissionBalanceEur: number,
      balanceCurrency: any,
      commissionCurrency: any,
  }

export const IPaymentClientFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "title",
    "balanceEur",
    "projects:id",
    "commissionBalanceEur",
    "balanceCurrency",
    "commissionCurrency"
];

export function usePaymentClientHookNae(id: number) : IPaymentClientModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorPaymentClientNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: NaeSSchemaMap.PaymentClient.schema,
        fields: IPaymentClientFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
