import React, { Fragment, useEffect } from 'react'

import { useTranslation } from 'react-i18next';
import { Table, Td, Th } from '@newageerp/v3.bundles.layout-bundle'
import { Checkbox } from '@newageerp/ui.form.base.form-pack';
import { NaeSSchemaMap } from '../../_generated/_custom/config/NaeSSchema';
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import { useBankAccountHookNae } from '../../_generated/_custom/hooks/useBankAccountHookNae';
import { useUList, useURequest } from '@newageerp/v3.bundles.hooks-bundle';
import { NaePathsMap } from '../../_generated/_custom/config/NaePaths';
import { PropsId } from '../../_generated/_custom/models-cache-data/types';

export default function UserWidget(props: PropsId) {
    const { t } = useTranslation();

    const [getUser, usersData] = useUList(NaeSSchemaMap.User.schema, ['id', 'bankAccounts:id']);
    const [getBankAccounts, bankAccountsData] = useUList(NaeSSchemaMap.BankAccount.schema, ['id']);

    const [addToUser, addToUserParams] = useURequest(NaePathsMap.post['BankAccountsUserControllerAdd']);
    const [removeFromUser, removeFromUserParams] = useURequest(NaePathsMap.post['BankAccountsUserControllerRemove']);

    const addBankAccount = (bankAccountId: number) => {
        addToUser({
            userId: props.id,
            bankAccountId: bankAccountId,
        }).then(() => {
            getUserData();
        })
    }

    const removeBankAccount = (bankAccountId: number) => {
        removeFromUser({
            userId: props.id,
            bankAccountId: bankAccountId,
        }).then(() => {
            getUserData();
        })
    }

    const getData = () => {
        getBankAccounts(
            [],
            1,
            1000,
            [{ key: 'i.title', value: 'ASC' }]
        );
        getUserData();
    }
    const getUserData = () => {
        getUser(
            [
                {
                    "and": [
                        ["i.id", "=", props.id, true]
                    ]
                }
            ],
            1,
            1,
            [{ key: 'i.id', value: 'ASC' }]
        )
    }

    const element: any = usersData.data.data.length > 0 ? usersData.data.data[0] : undefined;

    useEffect(() => {
        getData();
    }, []);

    if (!element) {
        return <Fragment />
    }

    const isLoading = addToUserParams.loading || removeFromUserParams.loading;

    return (
        <Table
            className={isLoading ? 'tw3-animate-pulse' : ''}
            thead={
                <thead>
                    <tr>
                        <Th className='tw3-w-20'></Th>
                        <Th>{t('Account number')}</Th>
                    </tr>
                </thead>
            }
            tbody={
                <tbody>
                    {bankAccountsData.data.data.map((i: any) => {
                        return (
                            <BankAccountLine key={`bank-account-${i.id}`} id={i.id} element={element} addBankAccount={addBankAccount} removeBankAccount={removeBankAccount} />
                        )
                    })}
                </tbody>
            }
        />
    )
}

interface BankAccountLineProps {
    id: number,
    element: any,

    addBankAccount: (val: number) => void,
    removeBankAccount: (val: number) => void,
}
const BankAccountLine = (props: BankAccountLineProps) => {
    const bankAccount = useBankAccountHookNae(props.id);

    if (!bankAccount) {
        return <Fragment />
    }

    const userBankAccounts: number[] = props.element.bankAccounts ? props.element.bankAccounts.map((i: any) => i.id) : [];
    const isChecked = userBankAccounts.indexOf(bankAccount.id) >= 0;

    const toggle = () => {
        if (isChecked) {
            props.removeBankAccount(props.id);
        } else {
            props.addBankAccount(props.id);
        }
    }

    return <tr>
        <Td>
            <Checkbox value={isChecked} onChange={toggle} />
        </Td>
        <Td>
            {bankAccount.title}
        </Td>
    </tr>
}
