import { useEffect } from "react";
import { NaeSSchemaMap } from '../../_custom/config/NaeSSchema';
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorPaymentInfoChangeNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface IPaymentInfoChangeModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
  }

export const IPaymentInfoChangeFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges"
];

export function usePaymentInfoChangeHookNae(id: number) : IPaymentInfoChangeModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorPaymentInfoChangeNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: NaeSSchemaMap.PaymentInfoChange.schema,
        fields: IPaymentInfoChangeFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
