import React, { Fragment } from 'react'
import { useTemplatesLoader } from "@newageerp/v3.templates.templates-core";
import { Float } from '@newageerp/data.table.base';
import classNames from 'classnames';

interface Props {
    fieldKey: string,
}

export default function FloatColorize(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const { element } = tData;

    if (!element) {
        return <Fragment />;
    }

    const value = element[props.fieldKey];

    return (
        <div className={
            classNames(
                "tw3-text-right",
                {'tw3-text-red-500': element.type === 'out'},
                {'tw3-text-teal-700': element.type === 'in'},
            )
        }>
        <Float value={value} accuracy={2} />
        </div>
    )
}
