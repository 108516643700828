import React, { Fragment, useEffect, useState } from 'react'
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { SFSSocketService } from '../../v3/navigation/NavigationComponent';
import { getSchemaByClassName } from '../../v3/utils';

export default function DataCacheSocketComponent() {
    const { dataCache, userState } = useTemplatesCore();

    const { addToQueue } = useDataCache();
    const [socketItem, setSocketItem] = useState();

    const onSocketDoRequest = (data: any) => {
        let schema = getSchemaByClassName(data.schema);

        let result = dataCache.getDataCacheForSchema(data.schema);

        if (result) {
            const dataToCheck = result.dataToCheck.map((el: any) => el.id);

            const cacheDataProject: any = dataCache.cacheData({}).find((el: any) => el.schema === schema)

            if (dataToCheck.indexOf(data.id) >= 0 || (!!cacheDataProject && cacheDataProject.forceSync)) {
                const item = {
                    elementId: data.id,
                    schema: schema,
                    fields: result.fields,
                }
                addToQueue(item);
            }
        }
    }

    const onForceSocketDoRequest = (data: any) => {
                if (data.schema === 'Note') {
            let result = dataCache.getDataCacheForSchema('Note');
            if (result) {
                const item = {
                    elementId: data.id,
                    schema: 'note',
                    fields: result.fields,
                }
                addToQueue(item);
            }
        }
            }

    useEffect(() => {
        if (socketItem) {
            onSocketDoRequest(socketItem);
        }
    }, [socketItem]);

    useEffect(() => {
        SFSSocketService.addCallback(
            "data-update-all",
            "data-update-all",
            setSocketItem
        );
        return () => {
            SFSSocketService.removeCallback(
                "data-update-all",
                "data-update-all"
            );
        };
    }, []);

    useEffect(() => {
        SFSSocketService.subscribe(`notes-${userState.id}`);
        SFSSocketService.subscribe(`notes-${userState.permissionGroup}`);

        SFSSocketService.addCallback(
            `notes-create`,
            `notes-create`,
            onForceSocketDoRequest
        );
        return () => {
            SFSSocketService.unsubscribe(`notes-${userState.id}`);
            SFSSocketService.unsubscribe(`notes-${userState.permissionGroup}`);

            SFSSocketService.removeCallback(
                `notes-create`,
                `notes-create`,
            );
        };
    }, [userState]);
    return (
        <Fragment />
    )
}
