import { useEffect } from "react";
import { NaeSSchemaMap } from '../../_custom/config/NaeSSchema';
import { useDataCache } from "@newageerp/v3.app.data-cache-provider";
import { useSelector } from "@newageerp/v3.templates.templates-core";
import { SelectorBankAccountNae } from "../models/ormSelectors";

interface ChildId {
  id: number
}

export interface IBankAccountModelNae {
    _viewTitle: string,
      id: number,
      scopes: any,
      badges: any,
      title: string,
      balanceEur: number,
      commissionBalanceEur: number,
      currency: {
              id: number,
                    currency: string,
            },
  }

export const IBankAccountFieldsNae = [
    "_viewTitle",
    "id",
    "scopes",
    "badges",
    "title",
    "balanceEur",
    "commissionBalanceEur",
    "currency.id",
    "currency.currency"
];

export function useBankAccountHookNae(id: number) : IBankAccountModelNae {
  // @ts-ignore
  const element = useSelector((state) => SelectorBankAccountNae(state, id));

  const { addToQueue } = useDataCache();

  useEffect(() => {
    if (!element) {
      addToQueue({
        elementId: id,
        schema: NaeSSchemaMap.BankAccount.schema,
        fields: IBankAccountFieldsNae,
      });
    }
  }, [element, id]);

  return element;
}
