import { INaeProperty } from "../../v3/utils";
export const NaeSProperties: INaeProperty[] = [
    {
        "schema": "bank-account",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "BankAccount",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "bank-account",
        "key": "balanceCurrency",
        "type": "array",
        "format": "string",
        "title": "Balance",
        "additionalProperties": [],
        "description": "",
        "className": "BankAccount",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "bank-account",
        "key": "balanceEur",
        "type": "number",
        "format": "float",
        "title": "Balance",
        "additionalProperties": [],
        "description": "",
        "className": "BankAccount",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "bank-account",
        "key": "commissionBalanceEur",
        "type": "number",
        "format": "float",
        "title": "Commission",
        "additionalProperties": [],
        "description": "",
        "className": "BankAccount",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "bank-account",
        "key": "commissionCurrency",
        "type": "array",
        "format": "string",
        "title": "Commission",
        "additionalProperties": [],
        "description": "",
        "className": "BankAccount",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "bank-account",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "BankAccount",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "bank-account",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Get the value of creator",
        "additionalProperties": [],
        "description": "",
        "className": "BankAccount",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "bank-account",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "BankAccount",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "bank-account",
        "key": "description",
        "type": "string",
        "format": "text",
        "title": "Description",
        "additionalProperties": [],
        "description": "",
        "className": "BankAccount",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "bank-account",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Get the value of doer",
        "additionalProperties": [],
        "description": "",
        "className": "BankAccount",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "bank-account",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "BankAccount",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "bank-account",
        "key": "inTransfers",
        "type": "array",
        "format": "bank-transfer",
        "title": "Get the value of inTransfers",
        "additionalProperties": [
            {
                "mapped": "toAccount"
            }
        ],
        "description": "",
        "className": "BankAccount",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "bank-account",
        "key": "initBalance",
        "type": "number",
        "format": "float",
        "title": "Init balance",
        "additionalProperties": [],
        "description": "",
        "className": "BankAccount",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "bank-account",
        "key": "outTransfers",
        "type": "array",
        "format": "bank-transfer",
        "title": "Get the value of outTransfers",
        "additionalProperties": [
            {
                "mapped": "fromAccount"
            }
        ],
        "description": "",
        "className": "BankAccount",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "bank-account",
        "key": "payments",
        "type": "array",
        "format": "payment",
        "title": "Get the value of payments",
        "additionalProperties": [
            {
                "mapped": "bankAccount"
            }
        ],
        "description": "<p><br><\/p>",
        "className": "BankAccount",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "bank-account",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "BankAccount",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "bank-account",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "BankAccount",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "bank-account",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Title",
        "additionalProperties": [],
        "description": "",
        "className": "BankAccount",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "bank-account",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "BankAccount",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "bank-account",
        "key": "users",
        "type": "array",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "BankAccount",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "bank-transfer",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created At",
        "additionalProperties": [],
        "description": "",
        "className": "BankTransfer",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "bank-transfer",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Get the value of creator",
        "additionalProperties": [],
        "description": "",
        "className": "BankTransfer",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "bank-transfer",
        "key": "currencyRate",
        "type": "number",
        "format": "float",
        "title": "Currency rate",
        "additionalProperties": [],
        "description": "",
        "className": "BankTransfer",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "bank-transfer",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Get the value of doer",
        "additionalProperties": [],
        "description": "",
        "className": "BankTransfer",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "bank-transfer",
        "key": "fromAccount",
        "type": "rel",
        "format": "bank-account",
        "title": "From account",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "BankTransfer",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "bank-transfer",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "BankTransfer",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "bank-transfer",
        "key": "inTotal",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "BankTransfer",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "bank-transfer",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "BankTransfer",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "bank-transfer",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "BankTransfer",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "bank-transfer",
        "key": "toAccount",
        "type": "rel",
        "format": "bank-account",
        "title": "To account",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "BankTransfer",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "bank-transfer",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Total",
        "additionalProperties": [],
        "description": "",
        "className": "BankTransfer",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "bank-transfer",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "BankTransfer",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "bookmark",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "bookmark",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Get the value of creator",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "bookmark",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Get the value of doer",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "bookmark",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "bookmark",
        "key": "parentId",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "bookmark",
        "key": "parentSchema",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "bookmark",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "bookmark",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Bookmark",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "client",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "client",
        "key": "buyerDetails",
        "type": "string",
        "format": "text",
        "title": "Details",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "client",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "client",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "client",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Title",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "client",
        "key": "users",
        "type": "array",
        "format": "",
        "title": "Get the value of users",
        "additionalProperties": [],
        "description": "",
        "className": "Client",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "company",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "bankDetails",
        "type": "string",
        "format": "text",
        "title": "Bank details",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "company",
        "key": "companyDetails",
        "type": "string",
        "format": "text",
        "title": "Details",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "company",
        "key": "description",
        "type": "string",
        "format": "text",
        "title": "Description",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "company",
        "key": "director",
        "type": "string",
        "format": "",
        "title": "Director",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "file",
        "type": "array",
        "format": "string",
        "title": "Signature",
        "additionalProperties": [
            {
                "as": "file"
            }
        ],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "json",
        "as": "file",
        "naeType": "file"
    },
    {
        "schema": "company",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "company",
        "key": "logo",
        "type": "array",
        "format": "string",
        "title": "Logo",
        "additionalProperties": [
            {
                "as": "file"
            }
        ],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "json",
        "as": "file",
        "naeType": "file"
    },
    {
        "schema": "company",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "company",
        "key": "template",
        "type": "string",
        "format": "",
        "title": "Template",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "enum": [
            {
                "label": "",
                "value": ""
            },
            {
                "label": "Template 1",
                "value": "tpl-1"
            },
            {
                "label": "Template 2",
                "value": "tpl-2"
            },
            {
                "label": "Template 3",
                "value": "tpl-3"
            }
        ],
        "naeType": "enum_text"
    },
    {
        "schema": "company",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Title",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "company",
        "key": "users",
        "type": "array",
        "format": "",
        "title": "Get the value of users",
        "additionalProperties": [],
        "description": "",
        "className": "Company",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "currency",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Currency",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "currency",
        "key": "currency",
        "type": "string",
        "format": "",
        "title": "Currency",
        "additionalProperties": [],
        "description": "",
        "className": "Currency",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "currency",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Currency",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "currency",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Currency",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "currency-rate",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [],
        "description": "",
        "className": "CurrencyRate",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "currency-rate",
        "key": "currencyRate",
        "type": "number",
        "format": "float",
        "title": "Currency rate",
        "additionalProperties": [],
        "description": "",
        "className": "CurrencyRate",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "currency-rate",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "CurrencyRate",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "currency-rate",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CurrencyRate",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "file",
        "key": "appproved",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "file",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "file",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Get the value of creator",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "file",
        "key": "deleted",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "file",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Get the value of doer",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "file",
        "key": "fileName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "file",
        "key": "folder",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "file",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "file",
        "key": "orgFileName",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "file",
        "key": "path",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "file",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "file",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "invoice",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "invoice",
        "key": "client",
        "type": "rel",
        "format": "client",
        "title": "Client",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice",
        "key": "company",
        "type": "rel",
        "format": "company",
        "title": "Company",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "invoice",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "invoice",
        "key": "dueDate",
        "type": "string",
        "format": "date",
        "title": "Due date",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "invoice",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "invoice",
        "key": "issuedBy",
        "type": "string",
        "format": "",
        "title": "Issued by",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "invoice",
        "key": "lines",
        "type": "array",
        "format": "invoice-line",
        "title": "Lines",
        "additionalProperties": [
            {
                "mapped": "invoice"
            }
        ],
        "description": "",
        "className": "Invoice",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "invoice",
        "key": "notes",
        "type": "string",
        "format": "text",
        "title": "Notes",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "invoice",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "invoice",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Serial number",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "invoice",
        "key": "taxableDate",
        "type": "string",
        "format": "date",
        "title": "Taxable date",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "invoice",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Total",
        "additionalProperties": [],
        "description": "",
        "className": "Invoice",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "invoice-line",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "invoice-line",
        "key": "invoice",
        "type": "rel",
        "format": "invoice",
        "title": "Invoice",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "invoice-line",
        "key": "priceEur",
        "type": "number",
        "format": "float",
        "title": "Price",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "invoice-line",
        "key": "quantity",
        "type": "number",
        "format": "float",
        "title": "Quantity",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "invoice-line",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Title",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "invoice-line",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "invoice-line",
        "key": "unit",
        "type": "string",
        "format": "",
        "title": "Unit",
        "additionalProperties": [],
        "description": "",
        "className": "InvoiceLine",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "note",
        "key": "content",
        "type": "string",
        "format": "text",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "note",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "note",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Get the value of creator",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "note",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Get the value of doer",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "note",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "note",
        "key": "notify",
        "type": "array",
        "format": "number|string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "json",
        "naeType": "array"
    },
    {
        "schema": "note",
        "key": "notifyAccept",
        "type": "array",
        "format": "number|string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "json",
        "naeType": "array"
    },
    {
        "schema": "note",
        "key": "parentId",
        "type": "integer",
        "format": "",
        "title": "parentId",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "note",
        "key": "parentSchema",
        "type": "string",
        "format": "",
        "title": "parentSchema",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "note",
        "key": "replyTo",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "note",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "note",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "payment",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "payment",
        "key": "balance",
        "type": "number",
        "format": "float",
        "title": "Balance",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "payment",
        "key": "balanceEur",
        "type": "number",
        "format": "float",
        "title": "Balance, client",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "payment",
        "key": "balanceEurIn",
        "type": "number",
        "format": "float",
        "title": "Balance IN, EUR",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "payment",
        "key": "balanceEurOut",
        "type": "number",
        "format": "float",
        "title": "Balance OUT, EUR",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "payment",
        "key": "bankAccount",
        "type": "rel",
        "format": "bank-account",
        "title": "Bank account",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment",
        "key": "bankCommisionPercent",
        "type": "number",
        "format": "float",
        "title": "Bank commision %",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "payment",
        "key": "bankCommisionTotal",
        "type": "number",
        "format": "float",
        "title": "Bank commision, EUR",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "payment",
        "key": "calculatedBankCommission",
        "type": "number",
        "format": "float",
        "title": "Bank commission",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "payment",
        "key": "calculatedCommission",
        "type": "number",
        "format": "float",
        "title": "Client commission",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "payment",
        "key": "commisionFormula",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "varchar",
        "enum": [
            {
                "label": "plus",
                "value": ""
            },
            {
                "label": "minus",
                "value": "minus"
            }
        ],
        "naeType": "enum_text"
    },
    {
        "schema": "payment",
        "key": "commisionPercent",
        "type": "number",
        "format": "float",
        "title": "Client commision %",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "payment",
        "key": "commisionTotal",
        "type": "number",
        "format": "float",
        "title": "Client commision, EUR",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "payment",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "payment",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "payment",
        "key": "currencyRate",
        "type": "number",
        "format": "float",
        "title": "Currency rate",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "payment",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "payment",
        "key": "description",
        "type": "string",
        "format": "text",
        "title": "Description",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "payment",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Doer",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment",
        "key": "file",
        "type": "array",
        "format": "string",
        "title": "File",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "payment",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "payment",
        "key": "invoice",
        "type": "array",
        "format": "string",
        "title": "Invoice",
        "additionalProperties": [
            {
                "as": "fileMultiple"
            }
        ],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "json",
        "as": "fileMultiple",
        "naeType": "fileMultiple"
    },
    {
        "schema": "payment",
        "key": "paymentCategory",
        "type": "rel",
        "format": "payment-category",
        "title": "Requisites",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment",
        "key": "paymentClient",
        "type": "rel",
        "format": "payment-client",
        "title": "Client",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment",
        "key": "paymentProject",
        "type": "rel",
        "format": "payment-project",
        "title": "Project",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "payment",
        "key": "serialNumber",
        "type": "string",
        "format": "",
        "title": "Serial number",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "payment",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "Status",
        "additionalProperties": [
            {
                "as": "status"
            }
        ],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "payment",
        "key": "subStatus",
        "type": "integer",
        "format": "",
        "title": "Sub status",
        "additionalProperties": [
            {
                "as": "status"
            }
        ],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "payment",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Total",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "payment",
        "key": "totalEur",
        "type": "number",
        "format": "float",
        "title": "Total, EUR",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "payment",
        "key": "totalWithSign",
        "type": "number",
        "format": "float",
        "title": "Total",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "payment",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "Type",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "varchar",
        "enum": [
            {
                "label": "Out",
                "value": "out"
            },
            {
                "label": "In",
                "value": "in"
            }
        ],
        "naeType": "enum_text"
    },
    {
        "schema": "payment",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated At",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "payment-category",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentCategory",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "payment-category",
        "key": "balanceCurrency",
        "type": "array",
        "format": "string",
        "title": "Balance",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentCategory",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "payment-category",
        "key": "balanceEur",
        "type": "number",
        "format": "float",
        "title": "Balance, client",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentCategory",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "payment-category",
        "key": "commissionBalanceEur",
        "type": "number",
        "format": "float",
        "title": "Commission, EUR",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentCategory",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "payment-category",
        "key": "commissionCurrency",
        "type": "array",
        "format": "string",
        "title": "Commission",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentCategory",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "payment-category",
        "key": "companyCode",
        "type": "string",
        "format": "",
        "title": "Company code",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentCategory",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment-category",
        "key": "description",
        "type": "string",
        "format": "text",
        "title": "Bank details",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentCategory",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "payment-category",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentCategory",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "payment-category",
        "key": "paymentProject",
        "type": "rel",
        "format": "payment-project",
        "title": "Project",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentCategory",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment-category",
        "key": "payments",
        "type": "array",
        "format": "payment",
        "title": "Get the value of payments",
        "additionalProperties": [
            {
                "mapped": "paymentCategory"
            }
        ],
        "description": "",
        "className": "PaymentCategory",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "payment-category",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentCategory",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "payment-category",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Title",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentCategory",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment-client",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentClient",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "payment-client",
        "key": "balanceCurrency",
        "type": "array",
        "format": "string",
        "title": "Balance",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentClient",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "payment-client",
        "key": "balanceEur",
        "type": "number",
        "format": "float",
        "title": "Balance, client",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentClient",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "payment-client",
        "key": "commissionBalanceEur",
        "type": "number",
        "format": "float",
        "title": "Commission, EUR",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentClient",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "payment-client",
        "key": "commissionCurrency",
        "type": "array",
        "format": "string",
        "title": "Commission",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentClient",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "payment-client",
        "key": "currency",
        "type": "rel",
        "format": "currency",
        "title": "Currency",
        "additionalProperties": [
            {
                "as": "select"
            }
        ],
        "description": "",
        "className": "PaymentClient",
        "isDb": true,
        "dbType": "int",
        "as": "select",
        "naeType": "object"
    },
    {
        "schema": "payment-client",
        "key": "description",
        "type": "string",
        "format": "text",
        "title": "Description",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentClient",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "payment-client",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentClient",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "payment-client",
        "key": "payments",
        "type": "array",
        "format": "payment",
        "title": "Get the value of payments",
        "additionalProperties": [
            {
                "mapped": "paymentClient"
            }
        ],
        "description": "",
        "className": "PaymentClient",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "payment-client",
        "key": "projects",
        "type": "array",
        "format": "payment-project",
        "title": "Projects",
        "additionalProperties": [
            {
                "mapped": "paymentClient"
            }
        ],
        "description": "",
        "className": "PaymentClient",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "payment-client",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentClient",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "payment-client",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Title",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentClient",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment-client",
        "key": "users",
        "type": "array",
        "format": "",
        "title": "Get the value of users",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentClient",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "payment-info-change",
        "key": "changes",
        "type": "array",
        "format": "string",
        "title": "Changes",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentInfoChange",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "payment-info-change",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created at",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentInfoChange",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "payment-info-change",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Get the value of creator",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentInfoChange",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment-info-change",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentInfoChange",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment-info-change",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentInfoChange",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "payment-info-change",
        "key": "payment",
        "type": "rel",
        "format": "payment",
        "title": "Payment",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentInfoChange",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment-info-change",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentInfoChange",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "payment-info-change",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentInfoChange",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "payment-project",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentProject",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "payment-project",
        "key": "balanceCurrency",
        "type": "array",
        "format": "string",
        "title": "Balance",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentProject",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "payment-project",
        "key": "balanceEur",
        "type": "number",
        "format": "float",
        "title": "Balance, client",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentProject",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "payment-project",
        "key": "commissionBalanceEur",
        "type": "number",
        "format": "float",
        "title": "Commission, EUR",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentProject",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "payment-project",
        "key": "commissionCurrency",
        "type": "array",
        "format": "string",
        "title": "Commission",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentProject",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "payment-project",
        "key": "description",
        "type": "string",
        "format": "text",
        "title": "Description",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentProject",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "payment-project",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentProject",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "payment-project",
        "key": "paymentClient",
        "type": "rel",
        "format": "payment-client",
        "title": "Client",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentProject",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment-project",
        "key": "payments",
        "type": "array",
        "format": "payment",
        "title": "Get the value of payments",
        "additionalProperties": [
            {
                "mapped": "paymentProject"
            }
        ],
        "description": "",
        "className": "PaymentProject",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "payment-project",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentProject",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "payment-project",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Title",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentProject",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "serial-number",
        "key": "className",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SerialNumber",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "serial-number",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SerialNumber",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "serial-number",
        "key": "incrementKey",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SerialNumber",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "serial-number",
        "key": "incrementValue",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SerialNumber",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "user",
        "key": "allowCreate",
        "type": "boolean",
        "format": "",
        "title": "Allow create users",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "user",
        "key": "allowedIp",
        "type": "string",
        "format": "text",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "user",
        "key": "bankAccounts",
        "type": "array",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "user",
        "key": "disableRemove",
        "type": "boolean",
        "format": "",
        "title": "Disable remove",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "user",
        "key": "disabled",
        "type": "boolean",
        "format": "",
        "title": "Disabled",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "user",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "Email",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "firstName",
        "type": "string",
        "format": "",
        "title": "First name",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "fullName",
        "type": "string",
        "format": "",
        "title": "Full name",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "user",
        "key": "lastName",
        "type": "string",
        "format": "",
        "title": "Last name",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "login",
        "type": "string",
        "format": "",
        "title": "Username",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "mailSignature",
        "type": "string",
        "format": "text",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "user",
        "key": "password",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "permissionGroup",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "phone",
        "type": "string",
        "format": "",
        "title": "Phone",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "plainPassword",
        "type": "string",
        "format": "password",
        "title": "Password",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "position",
        "type": "string",
        "format": "",
        "title": "Position",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "user",
        "key": "superUser",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    }
]