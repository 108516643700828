import { INaeSchema } from "../../v3/utils"
export const NaeSSchema: INaeSchema[] = [
    {
        "className": "BankAccount",
        "schema": "bank-account",
        "title": "Bank account",
        "titlePlural": "Bank accounts",
        "required": [
            "title",
            "currency"
        ],
        "scopes": []
    },
    {
        "className": "BankTransfer",
        "schema": "bank-transfer",
        "title": "Bank transfer",
        "titlePlural": "Bank transfers",
        "required": [
            "fromAccount",
            "toAccount",
            "total"
        ],
        "scopes": []
    },
    {
        "className": "Bookmark",
        "schema": "bookmark",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "Client",
        "schema": "client",
        "title": "Client",
        "titlePlural": "Clients",
        "required": [
            "title"
        ],
        "scopes": []
    },
    {
        "className": "Company",
        "schema": "company",
        "title": "Company",
        "titlePlural": "Companies",
        "required": [
            "title",
            "companyDetails"
        ],
        "scopes": []
    },
    {
        "className": "Currency",
        "schema": "currency",
        "title": "Currency",
        "titlePlural": "Currencies",
        "required": [],
        "scopes": []
    },
    {
        "className": "CurrencyRate",
        "schema": "currency-rate",
        "title": "Currency rate",
        "titlePlural": "",
        "required": [
            "currency",
            "currencyRate",
            "date"
        ],
        "scopes": []
    },
    {
        "className": "File",
        "schema": "file",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "Invoice",
        "schema": "invoice",
        "title": "Invoice",
        "titlePlural": "Invoices",
        "required": [
            "serialNumber",
            "date",
            "company",
            "client"
        ],
        "scopes": []
    },
    {
        "className": "InvoiceLine",
        "schema": "invoice-line",
        "title": "Line",
        "titlePlural": "Lines",
        "required": [],
        "scopes": []
    },
    {
        "className": "Note",
        "schema": "note",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "Payment",
        "schema": "payment",
        "title": "Payment",
        "titlePlural": "Payments",
        "required": [],
        "scopes": []
    },
    {
        "className": "PaymentCategory",
        "schema": "payment-category",
        "title": "Requisites",
        "titlePlural": "Requisites",
        "required": [],
        "scopes": []
    },
    {
        "className": "PaymentClient",
        "schema": "payment-client",
        "title": "Client",
        "titlePlural": "Clients",
        "required": [],
        "scopes": []
    },
    {
        "className": "PaymentInfoChange",
        "schema": "payment-info-change",
        "title": "Payment change",
        "titlePlural": "Payment changes",
        "required": [],
        "scopes": []
    },
    {
        "className": "PaymentProject",
        "schema": "payment-project",
        "title": "Project",
        "titlePlural": "Projects",
        "required": [],
        "scopes": []
    },
    {
        "className": "SerialNumber",
        "schema": "serial-number",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    },
    {
        "className": "User",
        "schema": "user",
        "title": "User",
        "titlePlural": "Users",
        "required": [],
        "scopes": [
            "disable-simple-create"
        ]
    }
]
        export const NaeSSchemaMap = {
    "User": {
        "className": "User",
        "schema": "user"
    },
    "BankAccount": {
        "className": "BankAccount",
        "schema": "bank-account"
    },
    "Payment": {
        "className": "Payment",
        "schema": "payment"
    },
    "PaymentProject": {
        "className": "PaymentProject",
        "schema": "payment-project"
    },
    "PaymentClient": {
        "className": "PaymentClient",
        "schema": "payment-client"
    },
    "PaymentCategory": {
        "className": "PaymentCategory",
        "schema": "payment-category"
    },
    "Currency": {
        "className": "Currency",
        "schema": "currency"
    },
    "Note": {
        "className": "Note",
        "schema": "note"
    },
    "File": {
        "className": "File",
        "schema": "file"
    },
    "SerialNumber": {
        "className": "SerialNumber",
        "schema": "serial-number"
    },
    "Bookmark": {
        "className": "Bookmark",
        "schema": "bookmark"
    },
    "CurrencyRate": {
        "className": "CurrencyRate",
        "schema": "currency-rate"
    },
    "Client": {
        "className": "Client",
        "schema": "client"
    },
    "Invoice": {
        "className": "Invoice",
        "schema": "invoice"
    },
    "InvoiceLine": {
        "className": "InvoiceLine",
        "schema": "invoice-line"
    },
    "Company": {
        "className": "Company",
        "schema": "company"
    },
    "BankTransfer": {
        "className": "BankTransfer",
        "schema": "bank-transfer"
    },
    "PaymentInfoChange": {
        "className": "PaymentInfoChange",
        "schema": "payment-info-change"
    }
}