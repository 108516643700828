import { INaeStatus } from "../../v3/utils";
export const NaeSStatuses: INaeStatus[] = [
    {
        "type": "status",
        "status": 0,
        "text": "не указан",
        "bgColor": "",
        "brightness": 0,
        "schema": "payment",
        "variant": "slate"
    },
    {
        "type": "subStatus",
        "status": 0,
        "text": "",
        "bgColor": "",
        "brightness": 0,
        "schema": "payment",
        "variant": "slate"
    },
    {
        "type": "status",
        "status": 5,
        "text": "заявка",
        "bgColor": "",
        "brightness": 0,
        "schema": "payment",
        "variant": "slateorange"
    },
    {
        "type": "status",
        "status": 10,
        "text": "ожидание",
        "bgColor": "",
        "brightness": 0,
        "schema": "payment",
        "variant": "amber"
    },
    {
        "type": "subStatus",
        "status": 10,
        "text": "AА - Агентская",
        "bgColor": "",
        "brightness": 0,
        "schema": "payment",
        "variant": "orange"
    },
    {
        "type": "status",
        "status": 15,
        "text": "аванс",
        "bgColor": "",
        "brightness": 0,
        "schema": "payment",
        "variant": "orange"
    },
    {
        "type": "status",
        "status": 20,
        "text": "зачислено",
        "bgColor": "",
        "brightness": 0,
        "schema": "payment",
        "variant": "teal"
    },
    {
        "type": "subStatus",
        "status": 20,
        "text": "AD - Агентская декларируемая",
        "bgColor": "",
        "brightness": 0,
        "schema": "payment",
        "variant": "purple"
    },
    {
        "type": "status",
        "status": 30,
        "text": "на оплату",
        "bgColor": "",
        "brightness": 0,
        "schema": "payment",
        "variant": "blue"
    },
    {
        "type": "subStatus",
        "status": 30,
        "text": "TS - Товарная",
        "bgColor": "",
        "brightness": 0,
        "schema": "payment",
        "variant": "blue"
    },
    {
        "type": "status",
        "status": 40,
        "text": "приоритет",
        "bgColor": "",
        "brightness": 0,
        "schema": "payment",
        "variant": "sky"
    },
    {
        "type": "status",
        "status": 45,
        "text": "аванс",
        "bgColor": "",
        "brightness": 0,
        "schema": "payment",
        "variant": "orange"
    },
    {
        "type": "status",
        "status": 50,
        "text": "исполнено",
        "bgColor": "",
        "brightness": 0,
        "schema": "payment",
        "variant": "teal"
    },
    {
        "type": "status",
        "status": 60,
        "text": "отклонено",
        "bgColor": "",
        "brightness": 0,
        "schema": "payment",
        "variant": "red"
    },
    {
        "type": "status",
        "status": 70,
        "text": "запрос документов",
        "bgColor": "",
        "brightness": 0,
        "schema": "payment",
        "variant": "orange"
    }
]