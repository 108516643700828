import React, { Fragment, useEffect } from 'react'

import { useTranslation } from 'react-i18next';
import { Table, Td, Th } from '@newageerp/v3.bundles.layout-bundle'
import { Checkbox } from '@newageerp/ui.form.base.form-pack';
import { NaeSSchemaMap } from '../../_generated/_custom/config/NaeSSchema';
import { WhiteCard } from '@newageerp/v3.bundles.widgets-bundle';
import { useCompanyHookNae } from '../../_generated/_custom/hooks/useCompanyHookNae';
import { useUList, useURequest } from '@newageerp/v3.bundles.hooks-bundle';
import { NaePathsMap } from '../../_generated/_custom/config/NaePaths';
import { PropsId } from '../../_generated/_custom/models-cache-data/types';

export default function UserCompanyWidget(props: PropsId) {
    const { t } = useTranslation();

    const [getUser, usersData] = useUList(NaeSSchemaMap.User.schema, ['id', 'companies:id']);
    const [getCompanies, companiesData] = useUList(NaeSSchemaMap.Company.schema, ['id']);

    const [addToUser, addToUserParams] = useURequest(NaePathsMap.post['CompaniesUserControllerAdd']);
    const [removeFromUser, removeFromUserParams] = useURequest(NaePathsMap.post['CompaniesUserControllerRemove']);

    const addCompany = (companyId: number) => {
        addToUser({
            userId: props.id,
            companyId: companyId,
        }).then(() => {
            getUserData();
        })
    }

    const removeCompany = (companyId: number) => {
        removeFromUser({
            userId: props.id,
            companyId: companyId,
        }).then(() => {
            getUserData();
        })
    }

    const getData = () => {
        getCompanies(
            [],
            1,
            1000,
            [{ key: 'i.title', value: 'ASC' }]
        );
        getUserData();
    }
    const getUserData = () => {
        getUser(
            [
                {
                    "and": [
                        ["i.id", "=", props.id, true]
                    ]
                }
            ],
            1,
            1,
            [{ key: 'i.id', value: 'ASC' }]
        )
    }

    const element: any = usersData.data.data.length > 0 ? usersData.data.data[0] : undefined;

    useEffect(() => {
        getData();
    }, []);

    if (!element) {
        return <Fragment />
    }

    const isLoading = addToUserParams.loading || removeFromUserParams.loading;

    return (
        <Table
            className={isLoading ? 'tw3-animate-pulse' : ''}
            thead={
                <thead>
                    <tr>
                        <Th className='tw3-w-20'></Th>
                        <Th>{t('Company')}</Th>
                    </tr>
                </thead>
            }
            tbody={
                <tbody>
                    {companiesData.data.data.map((i: any) => {
                        return (
                            <CompanyLine key={`company-${i.id}`} id={i.id} element={element} addCompany={addCompany} removeCompany={removeCompany} />
                        )
                    })}
                </tbody>
            }
        />
    )
}

interface CompanyLineProps {
    id: number,
    element: any,

    addCompany: (val: number) => void,
    removeCompany: (val: number) => void,
}
const CompanyLine = (props: CompanyLineProps) => {
    const company = useCompanyHookNae(props.id);

    if (!company) {
        return <Fragment />
    }

    const userCompanies: number[] = props.element.companies ? props.element.companies.map((i: any) => i.id) : [];
    const isChecked = userCompanies.indexOf(company.id) >= 0;

    const toggle = () => {
        if (isChecked) {
            props.removeCompany(props.id);
        } else {
            props.addCompany(props.id);
        }
    }

    return <tr>
        <Td>
            <Checkbox value={isChecked} onChange={toggle} />
        </Td>
        <Td>
            {company.title}
        </Td>
    </tr>
}
