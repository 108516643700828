import React, { Fragment } from 'react'
import { useTemplatesCore, useTemplatesLoader } from "@newageerp/v3.templates.templates-core";
import { NaeSProperties } from '../../../config/NaeSProperties';
import { getPropertyDataForSchema } from '../../../../v3/utils';
import { NaeSSchemaMap } from '../../../config/NaeSSchema';
import moment from 'moment'
import { StatusWidget } from '@newageerp/v3.bundles.widgets-bundle';

interface Props {
    fieldKey: string,
}

export default function Changes(props: Props) {
    const { data: tData } = useTemplatesLoader();
    const { modules } = useTemplatesCore();
    const { element } = tData;

    const keys = Object.keys(element.changes);

    return (
        <div className='tw3-space-y-1'>
            {keys.map((k: string, idx: number) => {
                const prop = getPropertyDataForSchema(NaeSSchemaMap.Payment.schema, k);
                const v = element.changes[k];

                let vPrev: any = '';
                let vNext: any = '';
                if (prop.format === 'date') {
                    vPrev = moment(v[0].date).format('YYYY-MM-DD');
                    vNext = moment(v[1].date).format('YYYY-MM-DD');
                } else if (prop.format === 'datetime') {
                    vPrev = moment(v[0].date).format('YYYY-MM-DD HH:mm');
                    vNext = moment(v[1].date).format('YYYY-MM-DD HH:mm');
                } else if (prop.as === 'status') {
                    const statusColorPrev = modules.statuses.color(NaeSSchemaMap.Payment.schema, k, v[0]);
                    const statusTextPrev = modules.statuses.title(NaeSSchemaMap.Payment.schema, k, v[0]);

                    const statusColorNext = modules.statuses.color(NaeSSchemaMap.Payment.schema, k, v[1]);
                    const statusTextNext = modules.statuses.title(NaeSSchemaMap.Payment.schema, k, v[1]);

                    vPrev = <StatusWidget
                        title={statusTextPrev}
                        className="tw3-max-w-[120px] tw3-truncate"
                        color={statusColorPrev}
                    >
                        {statusTextPrev}
                    </StatusWidget>;
                    vNext = <StatusWidget
                        title={statusTextNext}
                        className="tw3-max-w-[120px] tw3-truncate"
                        color={statusColorNext}
                    >
                        {statusTextNext}
                    </StatusWidget>;
                } else if (prop.type === 'string' || prop.type === 'number' || prop.type === 'rel') {
                    vPrev = v[0];
                    vNext = v[1];
                }

                return (
                    <div key={`k-${k}-${idx}`} className='tw3-flex tw3-gap-2 tw3-items-center'>
                        {vPrev != vNext && <Fragment>
                            <p className='tw3-font-bold'>{prop.title ? prop.title : k}</p>
                            <div className='tw3-flex tw3-gap-1 tw3-items-center'>
                                <span>{vPrev}</span><span>{` → `}</span><span>{vNext}</span>
                            </div>
                        </Fragment>}

                    </div>
                )
            })}
        </div>
    )
}
