import { useListDataSource } from '@newageerp/v3.app.list.list-data-source';
import { ToolbarButton } from '@newageerp/v3.bundles.buttons-bundle'
import { useURequest } from '@newageerp/v3.bundles.hooks-bundle';
import { useTranslation } from '@newageerp/v3.templates.templates-core'
import React from 'react'
import { NaePathsMap } from '../../_generated/_custom/config/NaePaths';

export default function PaymentsZip() {
    const { t } = useTranslation();

    const { data } = useListDataSource();

    const [doReq, doReqParams] = useURequest(NaePathsMap.post.PaymentsZipControllerExport);

    const doExport = () => {
        doReq({
            dataToken: data.cache.token
        }).then((res: any) => {
            window.open(res.data.link);
        })
    }

    return (
        <ToolbarButton
            iconName='file-zipper'
            loading={doReqParams.loading}
            onClick={doExport}
            title={t('Download zip invoices')}
        />
    )
}
