import { NumberCardWidget } from '@newageerp/v3.bundles.widgets-bundle';
import React, { Fragment } from 'react'
import { usePaymentCategoryHookNae } from '../../_generated/_custom/hooks/usePaymentCategoryHookNae';
import { PropsId } from '../../_generated/_custom/models-cache-data/types';

export default function PaymentCategoryWidget(props: PropsId) {
    const element = usePaymentCategoryHookNae(props.id);
    if (!element) {
        return <Fragment />
    }
    
    const balanceCurrencies = Object.keys(element.balanceCurrency);
    const commissionCurrencies = Object.keys(element.commissionCurrency);

    return (
        <Fragment>
            {balanceCurrencies.map(b => {
                const bVal = element.balanceCurrency[b];
                
                if (bVal === 0.0) {
                    return <Fragment key={`b-${b}`}/>
                }

                return <NumberCardWidget key={`b-${b}`} color='sky' isCompact={true} asFloat={true} currency={b} title='Balance' children={bVal} />
            })}
            {commissionCurrencies.map(b => {
                const bVal = element.commissionCurrency[b];

                if (bVal === 0.0) {
                    return <Fragment key={`c-${b}`}/>
                }
                
                return <NumberCardWidget key={`c-${b}`} color='purple' isCompact={true} asFloat={true} currency={b} title='Commission' children={bVal} />
            })}

            
        </Fragment>
    )
}
